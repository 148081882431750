/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { AddRounded, ChevronRightRounded, RemoveRounded } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { DRAWING_STATES, OVERVIEW_STATES, OVERVIEW_TYPES } from '../constants';
import { useAppControl } from '../providers/AppContext';
import { useLayout } from '../providers/LayoutContext';
import { useMapFunctions } from '../providers/MapFunctionsProvider';
import LinkButton from './atom/LinkButton';

export const generateLinkToProduct = (type, code) => {
    switch (type) {
        case OVERVIEW_TYPES.REPORT:
            return `/products/report/${code}`;
        case OVERVIEW_TYPES.POLICY:
            return `/products/policy/${code}`;
        case OVERVIEW_TYPES.MEPAR:
            return `/products/mepar/${code}`;
        default:
            return '/';
    }
};

const LinkAccordion = ({ title, nodes, linkTo, cb = () => {}, openOverview = true }) => {
    const [expanded, setExpanded] = useState([]);
    const { setDrawingState, setOverviewState } = useAppControl();
    const { matchesLgUp, selectedProduct, setSelectedProduct } = useLayout();
    const { resetMap } = useMapFunctions();
    const [_, setSearchParams] = useSearchParams();

    useEffect(() => {
        setExpanded(nodes.map((e) => e.name));
    }, [nodes, setExpanded]);

    const toggleExpanded = (meparCode) => {
        setExpanded((prevValues) => {
            if (prevValues.includes(meparCode)) {
                return prevValues.filter((e) => e !== meparCode);
            }
            return [...prevValues, meparCode];
        });
    };

    const expandAll = () => setExpanded(nodes.map((e) => e.name));

    const collapseAll = () => setExpanded([]);

    const handleClick = (child) => {
        if (selectedProduct?.code === child.code) {
            return;
        }
        if (openOverview) {
            setOverviewState(OVERVIEW_STATES.OPEN);
        }
        setSelectedProduct({ ...child });
        if (linkTo) {
            setSearchParams({
                [`${linkTo === OVERVIEW_TYPES.POLICY ? 'policyPlotId' : 'damagePlotId'}`]:
                    child.code,
            });
        }
        setDrawingState(DRAWING_STATES.NONE);
        cb(child);
    };

    const handleLinkClick = () => {
        setSelectedProduct(null);
        resetMap();
        setOverviewState(OVERVIEW_STATES.CLOSED);
    };

    if (nodes?.length === 0) {
        return null;
    }

    return (
        <Grid item xs={12} flexDirection="column" sx={{ pb: !matchesLgUp && 4 }}>
            {title && (
                <Typography variant="body1" fontWeight={700} sx={{ mt: 3, mb: 1 }}>
                    {title}
                </Typography>
            )}

            {nodes.some((e) => e.children.length > 0) && (
                <div style={{ marginBottom: '8px' }}>
                    {expanded.length > 0 ? (
                        <LinkButton startIcon={<RemoveRounded />} onClick={() => collapseAll()}>
                            Összes bezárása ({nodes.length})
                        </LinkButton>
                    ) : (
                        <LinkButton startIcon={<AddRounded />} onClick={() => expandAll()}>
                            Összes kinyitása ({nodes.length})
                        </LinkButton>
                    )}
                </div>
            )}
            <div>
                {nodes.map((node) => {
                    const hasChildren = node.children.length > 0;

                    return (
                        <div style={{ marginBottom: '8px' }} key={node.key || node.code}>
                            <Accordion
                                expanded={expanded.includes(node.name)}
                                sx={{ backgroundColor: '#fff' }}
                            >
                                <AccordionSummary
                                    expandIcon={
                                        hasChildren &&
                                        (expanded.includes(node.name) ? (
                                            <RemoveRounded
                                                onClick={() => toggleExpanded(node.name)}
                                            />
                                        ) : (
                                            <AddRounded onClick={() => toggleExpanded(node.name)} />
                                        ))
                                    }
                                    sx={{ borderBottom: '1px solid #d9e0e5' }}
                                >
                                    <Link
                                        to={generateLinkToProduct(linkTo, node.code)}
                                        onClick={handleLinkClick}
                                    >
                                        {node.name}
                                    </Link>
                                </AccordionSummary>
                                {hasChildren && (
                                    <AccordionDetails>
                                        {node.children.map((child) => (
                                            <div
                                                className={clsx(
                                                    'connecting-item',
                                                    selectedProduct?.code === child.code
                                                        ? 'active'
                                                        : ''
                                                )}
                                                key={child.code}
                                                onClick={() => handleClick(child)}
                                            >
                                                {child.name}
                                                <ChevronRightRounded />
                                            </div>
                                        ))}
                                    </AccordionDetails>
                                )}
                            </Accordion>
                        </div>
                    );
                })}
            </div>
        </Grid>
    );
};

export default LinkAccordion;
