export default function formatCurrency(amount) {
    if (amount === null) {
        return undefined;
    }

    const formatter = new Intl.NumberFormat('hu-HU', {
        style: 'currency',
        currency: 'HUF',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });

    return formatter
        .format(amount ?? 0)
        .replace(/\s/g, ' ')
        .replace('Ft', 'Ft');
}
